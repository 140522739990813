<template>
  <div>

    <b-tab>
      <template #title>
        <b-col
          align-self="start"
          cols="11"
        >
          <UilAnalysis
            class="logo"
            size="15px"
          />
          Summary V2
        </b-col>
        <b-col
          align-self="end"
          class="d-flex align-items-center justify-content-end"
          cols="1"
        />
      </template>
      <b-card class="invoice-preview-card">
        <b-card-body>
          <b-row>
            <div class="row">
              <div class="col-6">
                <h2 class="ml-2">Summary V2</h2></div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <!--         v-if="items.length > 0"-->
                  <div
                      class="d-flex align-items-center justify-content-end"
                      align-self="end"
                      style="text-align: end"
                  >
                    <b-dropdown
                        class="w-100 mb-1"
                        style="z-index: 13"
                        variant="outline-secondary"
                        text="Split Link"
                        checkbox-menu
                        allow-focus
                        dropdown
                    >
                      <template #button-content>
                        <span> <uil-columns /> Columns </span>
                      </template>
                      <b-dropdown-form class="w-100">
                        <b-form-group>
                          <template #label>
                            {{ baseSpecSelectedAll }}
                            <b-form-checkbox
                                v-model="allSelected"
                                aria-describedby="flavours"
                                aria-controls="flavours"
                                @change="toggleAllDrop"
                            >
                              {{ allSelected ? 'Deselect All' : 'Select All' }}
                            </b-form-checkbox>
                            <b-dropdown-divider style="margin-top:3px" />
                          </template>
                          <b-form-checkbox
                              v-for="(f, index) in fields"
                              :key="'check' + index"
                              v-model="displayColumnsCheck"
                              :value="f.key"
                          >{{ f.label }}
                          </b-form-checkbox>
                        </b-form-group>
                        <b-dropdown-divider />
                        <div style="display: flex; align-items: end; justify-content: end">
                          <b-button
                              class="py-0"
                              variant="outline-light"
                              style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                              @click="resetCols('cnstr_add')"
                          >Reset
                          </b-button>
                        </div>
                      </b-dropdown-form>
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <b-col cols="12" style="overflow-x: auto">
              <table
                v-if="profit_data.length>0"
                class="table"
              >
                <thead>
                  <tr>
                    <th class="text-left">
                      Code cost level 1
                    </th>
                    <th class="border_right_column text-left">
                      Code cost level 2
                    </th>
                    <th v-if="showColumnsFiltered('price')" class="text-right">
                      Price
                    </th>
                    <th v-if="showColumnsFiltered('cogs')" class="text-right">
                      COGS
                    </th>
                    <th v-if="showColumnsFiltered('price_sqft')" class="text-right">
                      Price/SQFT
                      <uil-info-circle id="price_sqft" class="m-0 p-0" />
                      <b-tooltip
                          title="Matches Procore Prime Contract"
                          :target="'price_sqft'"
                      />
                    </th>
                    <th v-if="showColumnsFiltered('cogs_sqft')" class="text-right border_right_column">
                      COGS/SQFT
                      <uil-info-circle id="cogs_sqft" class="m-0 p-0" />
                      <b-tooltip
                          title="Matches Procore Budget"
                          :target="'cogs_sqft'"
                      />
                    </th>
                    <th v-if="showColumnsFiltered('profit_sqft')" class="text-right">
                      PROFIT/SQFT
                      <uil-info-circle id="profit" class="m-0 p-0" />
                      <b-tooltip
                          title="Matches Procore Surplus"
                          :target="'profit'"
                      />
                    </th>
                    <th v-if="showColumnsFiltered('profit')" class="text-right">
                      PROFIT
                    </th>
                    <th v-if="showColumnsFiltered('rebate')" class="text-right">
                      Other Profit
                      <uil-info-circle id="rebate" class="m-0 p-0" />
                      <b-tooltip
                          title="Profit not captured in Procore"
                          :target="'rebate'"
                      />
                    </th>
                    <th v-if="showColumnsFiltered('rebate_sqft')" class="text-right">
                      Other Profit/SQFT
                      <uil-info-circle id="rebate_sqft" class="m-0 p-0" />
                      <b-tooltip
                          title="Profit not captured in Procore"
                          :target="'rebate_sqft'"
                      />
                    </th>
                    <th class="bg_totals text-right" v-if="showColumnsFiltered('rebate_profit')">
                      <b style="">Total Profit</b>
                      <uil-info-circle id="rebate_profit" class="m-0 p-0" />
                      <b-tooltip
                          title="Profit + Other Profit"
                          :target="'rebate_profit'"
                      />
                    </th>
                    <th class="bg_totals text-right" v-if="showColumnsFiltered('rebate_profit_sqft')">
                     <b style="">Total Profit/SQFT</b>
                      <uil-info-circle id="rebate_profit_sqft" class="m-0 p-0" />
                      <b-tooltip
                          title="Profit/SQFT + Other Profit/SQFT"
                          :target="'rebate_profit_sqft'"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(l1,idx) in level_1_keys"
                  >
                    <template v-for="l2, index in filterL1Keys(l1)">
                      <tr :key="index+'-'+l2.key">
                        <td class="text-left">
                          <!--the items on the array are the index that appears the first item -->
                          <b v-if="index===0">{{ l2.category_name }}</b>
                        </td>
                        <td class="border_right_column text-left">
                          <div class="d-flex">
                            {{ l2.level_2_name }}
                            <b-button class="py-0" variant="light" style="background-color: transparent!important; border: 0px; height: 20px" @click="l2.is_expanded=!l2.is_expanded">
                              <uil-angle-right-b v-if="!l2.is_expanded"/>
                              <uil-angle-down v-else class="icon"/>
                            </b-button>
                          </div>
                        </td>
                        <td v-if="showColumnsFiltered('price')" class="text-right">
                          {{ formatter.format(((l2.total_price*1))) }}
                        </td>
                        <td v-if="showColumnsFiltered('cogs')" class="text-right">
                          {{ formatter.format(((l2.total_cost*1))) }}
                        </td>
                        <td v-if="showColumnsFiltered('price_sqft')" class="text-right">
                          {{ formatter.format(((l2.total_price*1))/quote.sqft) }}
                        </td>
                        <td v-if="showColumnsFiltered('cogs_sqft')" class="text-right border_right_column">
                          {{ formatter.format(((l2.total_cost*1))/quote.sqft) }}
                        </td>
                        <td v-if="showColumnsFiltered('profit_sqft')" class="text-right">
                          {{ formatter.format((l2.profit_sqft*1)) }}
                        </td>
                        <td v-if="showColumnsFiltered('profit')" class="text-right">
                          {{ formatter.format((l2.profit_total*1)) }}
                        </td>
                        <td v-if="showColumnsFiltered('rebate')" class="text-right">
                          {{formatter.format((l2.rebate*1 + l2.other_profit_total*1))}}
                        </td>
                        <td v-if="showColumnsFiltered('rebate_sqft')" class="text-right">
                          {{formatter.format(((l2.rebate*1 + l2.other_profit_total*1))/quote.sqft)}}
                        </td>
                        <td v-if="showColumnsFiltered('rebate_profit')" class="text-right bg_totals">
                          {{ formatter.format((l2.profit_total*1)+(l2.rebate*1)+l2.other_profit_total*1) }}
                        </td>
                        <td v-if="showColumnsFiltered('rebate_profit_sqft')" class="text-right bg_totals">
                          {{ formatter.format((((l2.profit_total*1)+(l2.rebate*1+l2.other_profit_total*1))/quote.sqft)) }}
                        </td>
                      </tr>
                      <tr :class="l2.is_expanded?'visible':'hidden'" v-for="item in l2.items.filter(i=>!i.is_adjustment)" :key="item.id" class="bg-light text-muted">
                        <td class="border_right_column text-left"/>
                        <td class="border_right_column text-left" >
                          <div v-if="item.name">
                            <uil-arrow-growth v-if="item.specification_swap||(constructionAdjustments(item.construction_work_id, l2.items).total_cost!=0&&constructionAdjustments(item.construction_work_id, l2.items).price!=0)" style="color: #ff9f43" />
                            {{item.specification_swap?item.specification_swap.name:item.name}} <br>
                            <small v-if="item.details">
                              {{item.specification_swap?item.specification_swap.internal_note:item.details}}
                            </small>
                          </div>
                          <div v-if="item.uniformat=='E2050.30.03'">
                            Delivery & Installation
                          </div>
                          <div v-if="item.uniformat=='D6030.10.02'">
                            Delivery & Installation
                          </div>
                          <div v-if="item.is_adjustment && item.is_base_spec">
                            Adjustment/ Custom Spec
                          </div>
                        </td>
                        <td v-if="showColumnsFiltered('price')" class="text-right">
                          {{ formatter.format(((item.total_price*1+constructionAdjustments(item.construction_work_id, l2.items).total_price*1))) }}
                        </td>
                        <td v-if="showColumnsFiltered('cogs')" class="text-right">
                          {{ formatter.format(((item.total_cost*1)+constructionAdjustments(item.construction_work_id, l2.items).total_cost*1)) }}
                        </td>
                        <td v-if="showColumnsFiltered('price_sqft')" class="text-right">
                          {{ formatter.format(((item.total_price*1)+(constructionAdjustments(item.construction_work_id, l2.items).total_price*1))/quote.sqft) }}
                        </td>
                        <td v-if="showColumnsFiltered('cogs_sqft')" class="text-right border_right_column">
                          {{ formatter.format(((item.total_cost*1)+(constructionAdjustments(item.construction_work_id, l2.items).total_cost*1))/quote.sqft) }}
                        </td>
                        <td v-if="showColumnsFiltered('profit_sqft')" class="text-right">
                          {{ formatter.format((((item.profit*1+((constructionAdjustments(item.construction_work_id, l2.items).total_price*1))-(constructionAdjustments(item.construction_work_id, l2.items).total_cost*1)))/quote.sqft)) }}
                        </td>
                        <td v-if="showColumnsFiltered('profit')" class="text-right">
                          {{ formatter.format(((item.profit*1+((constructionAdjustments(item.construction_work_id, l2.items).total_price*1))-((constructionAdjustments(item.construction_work_id, l2.items).total_cost*1))))) }}
                        </td>
                        <td v-if="showColumnsFiltered('rebate')" class="text-right">
                          {{formatter.format((item.rebate_total*1 + item.other_profit_total*1+(constructionAdjustments(item.construction_work_id, l2.items).rebate_total*1)))}}
                        </td>
                        <td v-if="showColumnsFiltered('rebate_sqft')" class="text-right">
                          {{formatter.format(((item.rebate_total*1 + item.other_profit_total*1+(constructionAdjustments(item.construction_work_id, l2.items).rebate_total*1)))/quote.sqft)}}
                        </td>
                        <td v-if="showColumnsFiltered('rebate_profit')" class="text-right bg_totals">
                          {{ formatter.format(calculateRebateProfit(item, l2)) }}
                        </td>
                        <td v-if="showColumnsFiltered('rebate_profit_sqft')" class="text-right bg_totals">
                          {{ formatter.format(calculateRebateProfitPerSqft(item, l2)) }}
                        </td>
                      </tr>
                    </template>
                    <tr class="bg-light" :key="idx+'-'+l1.key">
                      <td colspan="2" class="border_right_column total_borders">
                        <b> {{ filterL1Keys(l1)[0].category_name }} Total</b>
                      </td>
                      <td v-if="showColumnsFiltered('price')" class="text-right total_borders">
                        {{ formatter.format(groupSumPrice(l1)) }}
                      </td>
                      <td v-if="showColumnsFiltered('cogs')" class="text-right total_borders border_right_column ">
                        {{ formatter.format(groupSumCost(l1)) }}
                      </td>
                      <td v-if="showColumnsFiltered('price_sqft')" class="text-right total_borders">
                        {{ formatter.format(groupSumPrice(l1)/quote.sqft) }}
                      </td>
                      <td v-if="showColumnsFiltered('cogs_sqft')" class="text-right total_borders border_right_column">
                        {{ formatter.format(groupSumCost(l1)/quote.sqft) }}
                      </td>
                      <td v-if="showColumnsFiltered('profit_sqft')" class="text-right total_borders">
                        {{ formatter.format((groupSumProfit(l1)*1)/quote.sqft) }}
                      </td>
                      <td v-if="showColumnsFiltered('profit')" class="text-right total_borders">
                        {{ formatter.format(groupSumProfit(l1)*1) }}
                      </td>
                      <td v-if="showColumnsFiltered('rebate')" class="text-right total_borders">
                        {{ formatter.format(rebateSumCat(l1)+otherProfitSumCat(l1)) }}
                      </td>
                      <td v-if="showColumnsFiltered('rebate_sqft')" class="text-right total_borders">
                        {{ formatter.format((rebateSumCat(l1)+otherProfitSumCat(l1))/quote.sqft) }}
                      </td>
                      <td v-if="showColumnsFiltered('rebate_profit')" class="text-right total_borders">
                        {{ formatter.format((groupSumProfit(l1)*1)+rebateSumCat(l1)+otherProfitSumCat(l1)) }}
                      </td>
                      <td v-if="showColumnsFiltered('rebate_profit_sqft')" class="text-right total_borders">
                        {{ formatter.format((groupSum(l1)+rebateSumCat(l1)+otherProfitSumCat(l1))/quote.sqft) }}
                      </td>
                    </tr>
                  </template>
                  <tr class="bg_totals">
                    <td colspan="2" class="border_right_column">
                      <b>  Total</b>
                    </td>
                    <td v-if="showColumnsFiltered('price')" class="text-right text-bold">{{ formatter.format((groupSumPrice('A')+groupSumPrice('B')+groupSumPrice('C')+groupSumPrice('D'))) }}</td>
                    <td v-if="showColumnsFiltered('cogs')" class="text-right text-bold ">
                      {{ formatter.format((groupSumCost('A')+groupSumCost('B')+groupSumCost('C')+groupSumCost('D'))) }}
                    </td>
                    <td v-if="showColumnsFiltered('price_sqft')" class="text-right text-bold">{{ formatter.format((groupSumPrice('A')+groupSumPrice('B')+groupSumPrice('C')+groupSumPrice('D'))/quote.sqft) }}</td>
                    <td v-if="showColumnsFiltered('cogs_sqft')" class="text-right text-bold border_right_column">
                      {{ formatter.format((groupSumCost('A')+groupSumCost('B')+groupSumCost('C')+groupSumCost('D'))/quote.sqft) }}
                    </td>
                    <td v-if="showColumnsFiltered('profit_sqft')" class="text-right text-bold">
                      {{ formatter.format((groupSum('A')+groupSum('B')+groupSum('C')+groupSum('D'))/quote.sqft) }}
                    </td>
                      <td v-if="showColumnsFiltered('profit')" class="text-right text-bold">
                        {{ formatter.format(totalProfit) }}
                      </td>
                    <td v-if="showColumnsFiltered('rebate')" class="text-right text-bold">
                      {{ formatter.format((rebateSum*1+otherProfitSum*1)) }}
                    </td>
                    <td v-if="showColumnsFiltered('rebate_sqft')" class="text-right text-bold">
                      {{ formatter.format((rebateSum*1+otherProfitSum*1)/quote.sqft) }}
                    </td>
                    <td v-if="showColumnsFiltered('rebate_profit')" class="text-right text-bold">
                      {{ formatter.format(totalProfit+rebateSum+otherProfitSum) }}
                    </td>
                    <td v-if="showColumnsFiltered('rebate_profit_sqft')" class="text-right text-bold">
                      {{ formatter.format((((groupSum('A')+groupSum('B')+groupSum('C')+groupSum('D'))+rebateSum*1+otherProfitSum*1)/quote.sqft)) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-tab>
  </div>
</template>

<script>
import {
  BButton, BTooltip,
  BCard, BCardBody, BCol, BDropdown, BDropdownDivider, BDropdownForm, BFormCheckbox, BFormGroup, BRow, BTab, BFormInput
} from 'bootstrap-vue'
import { UilAnalysis, UilInfoCircle, UilAngleRightB,UilAngleDown, UilArrowGrowth } from '@iconscout/vue-unicons'
import {
  UilColumns,
} from '@iconscout/vue-unicons'
export default {
  components: {
    BDropdownForm, BFormCheckbox, BButton, BDropdownDivider, BFormGroup, BDropdown,
    BCol, BRow, BTab, BCard, BCardBody, UilAnalysis,UilColumns, BFormInput, BTooltip, UilInfoCircle,
    UilAngleRightB,UilAngleDown,UilArrowGrowth
  },
  props: {
    quote: {
      type: Object,
    },
  },
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      level_1_keys: ['A', 'B', 'C', 'D'],
      profit_data: [],
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      //variables for columns display
      allSelected: false,
      displayColumnsCheck:[
        'key',
        'level_2',
        'price_sqft',
        'cogs_sqft',
        'profit_sqft',
          'rebate_sqft',
          'rebate_profit_sqft',
      ],
      fields: [
        {
          label: 'Price',
          key: 'price',
        },
        {
          label: 'COGS',
          key: 'cogs',
        },
        {
          label: 'Price/SQFT',
          key: 'price_sqft',
        },
        {
          label: 'COGS/SQFT',
          key: 'cogs_sqft',
        },
        {
          label: 'PROFIT',
          key: 'profit',
        },
        {
          label: 'PROFIT/SQFT',
          key: 'profit_sqft',
        },
        //hide rebate columns
        {
          label: 'Other profit',
          key: 'rebate',
        },
        {
          label: 'Other profit/SQFT',
          key: 'rebate_sqft',
        },
        {
          label: 'Profit + Other profit',
          key: 'rebate_profit',
        },
        {
          label: 'Profit + Other profit/SQFT',
          key: 'rebate_profit_sqft',
        },
      ]
    }
  },
  computed: {
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length <=9) {
        this.allSelected = false
      } else {
        this.allSelected = true
      }
    },
    rebateSum(){
      return this.profit_data.reduce((total, obj) => obj.rebate * 1 + total, 0)
    },
    otherProfitSum(){
      return this.profit_data.reduce((total, obj) => obj.other_profit_total * 1 + total, 0)
    },
    totalProfit() {
      return  ['A', 'B', 'C', 'D'].reduce((sum, group) => sum + this.groupSumProfit(group), 0);

    }
  },
  mounted() {
    this.getProfitability()
  },
  methods: {
    getProfitability() {
      this.$http
        .get(`/quote-profitability/${this.quoteId}`)
        .then(response => {
          this.profit_data = response.data
          // Get only the keys that exist in level_1_keys and sort them
          this.level_1_keys= [...new Set(
            this.profit_data
              .filter(obj => this.level_1_keys.includes(obj.key)) // Filter objects whose 'key' is in level_1_keys
              .map(obj => obj.key) // Extract only the 'key' values
          )].sort(); // Sort the keys
          // this.level_1_keys = filteredKeys
          this.initRebateCalcs()
        })
        .catch(error => {
          this.showToast('danger', error.response.data.message)
        })
    },
    initRebateCalcs(){
      this.profit_data = this.profit_data.map(p => ({
        ...p,
        profit_sqft: (p.profit_total * 1 )/this.quote.sqft,
        rebate: p.rebate,
      }));
    },
    filterL1Keys(key) {
      return this.profit_data.filter(p => p.key === key)
    },
    groupSum(key) {
      return this.profit_data.filter(p => p.key === key).reduce((total, obj) => obj.profit_total * 1 + total, 0)
    },
    groupSumCost(key) {
      const total_cogs = this.profit_data.filter(p => p.key === key).reduce((total, obj) => obj.total_cost * 1 + total, 0)
      return total_cogs * 1
    },
    groupSumPrice(key) {
      const total_price = this.profit_data.filter(p => p.key === key).reduce((total, obj) => obj.total_price * 1 + total, 0)
      return total_price * 1
    },
    groupSumProfit(key) {
      return this.profit_data.filter(p => p.key === key).reduce((total, obj) => obj.profit_total * 1 + total, 0)
    },
    rebateSumCat(key){
      return this.profit_data.filter(p => p.key === key).reduce((total, obj) => obj.rebate * 1 + total, 0)
    },
    otherProfitSumCat(key){
      return this.profit_data.filter(p => p.key === key).reduce((total, obj) => obj.other_profit_total * 1 + total, 0)
    },
    calculateRebateProfit(item, l2) {
      const adjustments = this.constructionAdjustments(item.construction_work_id, l2.items);
      const totalProfit = item.profit*1 + (adjustments.total_price*1 - adjustments.total_cost*1);
      const totalRebate = item.rebate_total*1 + adjustments.other_profit_total*1 + adjustments.rebate_total*1 +item.other_profit_total*1;
      return totalProfit + totalRebate;
    },
    calculateRebateProfitPerSqft(item, l2) {
      const totalRebateProfit = this.calculateRebateProfit(item, l2);
      return totalRebateProfit / this.quote.sqft;
    },
    showColumnsFiltered(col) {
      let isSelected = false
          if (this.displayColumnsCheck.length > 0) {
            const res = this.displayColumnsCheck.filter(c => c == col)
            if (res.length != 0) {
              isSelected = true
            }
          }
          return isSelected
    },
    resetCols() {
      this.displayColumnsCheck = [
        'key',
        'level_2',
        'price_sqft',
        'cogs_sqft',
        'profit_sqft',
        'rebate_sqft',
        'rebate_profit_sqft',
      ]
    },
    toggleAllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = [
          'key',
          'level_2',
        ]
        if (this.fields.length > 0) {
          this.fields.map(f => {
            this.displayColumnsCheck.push(f.key)
          })
        }
      } else {
        this.displayColumnsCheck = ['key',
          'level_2',]
      }
    },
    constructionAdjustments(constructionWorkId,items) {
      let filteredItems = items.filter(i=>i.is_adjustment)
      let item = filteredItems.find((n) => n.construction_work_id == constructionWorkId);
      if(item){
        return item
      }else{
        return{
          total_cost: 0,
          total_price:0,
          rebate_total:0,
          other_profit_total:0
        }
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

/* Visible state */
.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}
</style>
