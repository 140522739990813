// update visible fields after spec swaps pending to update total_cogs and cogs/unit
<template>
  <div>
    <b-card-title>
      <b-row>
        <b-col cols="10">
          <h2>AV / IT</h2>
        </b-col>
        <b-col
          align-self="end"
          class="d-flex align-items-center justify-content-end"
          cols="2"
        >
          <h4 style="color:  rgba(59, 171, 255, 1) ">
            {{
              Number(
                avitGlobalSubTotal / quote.sqft + quote.options_avit_price_per_sqft
              ).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </h4>
          <h4>/sqft</h4>
        </b-col>
      </b-row>
    </b-card-title>
    <div>
      <!-- start base spec table -->
      <b-card>
        <UninformatTableGrouping
            v-if="avitItems.length > 0"
            :table_content="avitItems" :table_fields="
                                 quote.base_spec_version >= 3.4
          ? headers
          : headers.filter(
          (h) => h.value != 'actions' && h.value != 'internal_note'
          )
          "
            :display_columns_check="displayColumnsCheck"
            @clickSwapAction="showSwapSpec"
            :colCheckFields="fields"
        />
<!--        <b-row v-if="true">
          <b-col cols="12">
            <b-row align-h="end">
              <b-col cols="8">
                &lt;!&ndash; <h3 class="mt-2" style="color: #636363"><b>Base Spec</b></h3> &ndash;&gt;
              </b-col>
              <b-col cols="2">
                <b-form-input
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  hide-details
                  outlined
                  placeholder="Search"
                  class="my-1 mr-2"
                ></b-form-input>
              </b-col>
              <b-col
                v-if="false"
                cols="2"
                class="my-1 pl-0"
                align-self="end"
                style="text-align: end"
              >
                <div
                  class="d-flex align-items-center justify-content-end"
                  align-self="end"
                  style="text-align: end"
                >
                  <b-dropdown
                    class="w-100"
                    variant="outline-secondary"
                    checkbox-menu
                    allow-focus
                    dropup
                  >
                    <template #button-content>
                      <span> <uil-columns /> Columns </span>
                    </template>
                    <b-dropdown-form class="w-100">
                      <b-form-group>
                        <template #label>
                          {{ baseSpecSelectedAll }}
                          <b-form-checkbox
                            class="pb-0 mb-0"
                            v-model="allSelected"
                            @change="AllDrop"
                          >
                            {{ allSelected ? "Deselect All" : "Select All" }}
                          </b-form-checkbox>
                        </template>
                        <hr class="p-0 m-0 mb-1" />
                        <b-form-checkbox
                          v-model="displayColumnsCheck"
                          v-for="(f, index) in fields"
                          :key="'check' + index"
                          :value="f.key"
                          >{{ f.label }}
                        </b-form-checkbox>
                      </b-form-group>
                      <b-dropdown-divider />
                      <div style="display: flex; align-items: end; justify-content: end">
                        <b-button
                          class="py-0"
                          variant="outline-light"
                          style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                          @click="resetCols('base')"
                          >Reset</b-button
                        >
                      </div>
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
              </b-col>
            </b-row>
            &lt;!&ndash; :fixed-header="true" &ndash;&gt;
            <v-data-table
              v-if="avitItems.length > 0"
              :headers="
                quote.base_spec_version >= 3.4
                  ? headers
                  : headers.filter(
                      (h) => h.value != 'actions' && h.value != 'internal_note'
                    )
              "
              :items="avitItems"
              item-key="name"
              ref="myTable"
              @current-items="currentItems"
              @click:row="clicked"
              :search="search"
              :disable-pagination="true"
              :hide-default-footer="true"
            >
              <template v-slot:header.supply_price_per_unit="{ header }">
                {{ header.text }}
                <uil-info-circle id="supply_price_per_unit_av_it" />

                <b-tooltip
                  title="COGS / Unit + Markup / Unit"
                  :target="'supply_price_per_unit_av_it'"
                />
              </template>
              <template v-slot:header.unit_price="{ header }">
                {{ header.text }} <uil-info-circle id="unit_price_av_it" />
                <b-tooltip
                  title="Price / Unit + Delivery & install / Unit"
                  :target="'unit_price_av_it'"
                />
              </template>
              <template v-slot:header.total_price="{ header }">
                {{ header.text }} <uil-info-circle id="total_price_av_it" />
                <b-tooltip
                  title="(Price / Unit + Delivery & install / Unit) x Quantity"
                  :target="'total_price_av_it'"
                />
              </template>
              <template v-slot:header.total_supply_price="{ header }">
                {{ header.text }}
                <uil-info-circle id="total_supply_price_av_it" />
                <b-tooltip
                  title="(CS Unit Cost + Markup / Unit) x Quantity"
                  :target="'total_supply_price_av_it'"
                />
              </template>
              <template v-slot:header.unit_cost="{ header }">
                {{ header.text }} <uil-info-circle id="unit_cost_av_it" />
                <b-tooltip title="Cost of Goods Sold" :target="'unit_cost_av_it'" />
              </template>
              <template v-slot:header.total_cost="{ header }">
                {{ header.text }} <uil-info-circle id="total_cost_av_it" />
                <b-tooltip
                  title="Cost of Goods Sold x Quantity"
                  :target="'total_cost_av_it'"
                />
              </template>
              <template v-slot:header.cost_per_sqft="{ header }">
                {{ header.text }} <uil-info-circle id="cost_per_sqft_av_it" />
                <b-tooltip
                  title="(Cost of Goods Sold x Quantity) / SQFT"
                  :target="'cost_per_sqft_av_it'"
                />
              </template>
              <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
                <div class="d-flex">
                  <div style="display: inline-block; padding: 16px 0" :key="i">
                    {{ header.text }}
                  </div>
                  <div
                    style="float: right; margin-top: 8px"
                    id="attachId"
                    :key="'filter' + i"
                  >
                    <v-menu
                      :close-on-content-click="false"
                      :max-width="400"
                      :min-width="300"
                      bottom
                      right
                      attach="#attachId"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon
                            small
                            :style="
                              activeFilters[header.value] &&
                              activeFilters[header.value].length <
                                filters[header.value].length
                                ? 'color:black'
                                : 'color:#dedede'
                            "
                          >
                            mdi-filter-variant
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item-group>
                          <template v-for="item in filters[header.value]">
                            <v-list-item :key="`${item}`" :value="item" :ripple="false">
                              <template>
                                <v-list-item-action>
                                  <b-form-checkbox
                                    v-model="activeFilters[header.value]"
                                    :value="item"
                                  ></b-form-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="font-weight: normal; text-transform: none"
                                    >{{ item }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                      <v-row no-gutters style="background-color: #fff">
                        <v-col cols="6">
                          <b-button
                            block
                            @click="toggleAll(header.value)"
                            variant="outline-light"
                            style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                            >Select all</b-button
                          >
                        </v-col>
                        <v-col cols="6">
                          <b-button
                            block
                            @click="clearAll(header.value)"
                            variant="outline-light"
                            style="border-color: transparent; color:  rgba(59, 171, 255, 1) "
                            >Deselect all</b-button
                          >
                        </v-col>
                      </v-row>
                    </v-menu>
                  </div>
                </div>
              </template>
              &lt;!&ndash; start formatted data and custom formulas &ndash;&gt;
              <template v-slot:item.mark_up_per_unit="{ item }">
                {{
                  Number(
                    markup(item.supply_price, item.unit_cost).toFixed(2)
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_markup="{ item }">
                {{
                  Number(
                    (markup(item.supply_price, item.unit_cost) * item.qty).toFixed(2)
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_cost="{ item }">
                {{
                  Number(item.total_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>

              <template v-slot:item.cost_per_sqft="{ item }">
                {{
                  Number(item.cost_per_sqft.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                  })
                }}
              </template>
              <template v-slot:item.total_delivery_install="{ item }">
                <div v-if="!item.specification_swap">
                  {{
                    Number(item.total_delivery_install).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else>
                  {{
                    Number(
                      Number(item.specification_swap.total_delivery_install)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.total_price="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_price * 1 +
                        item.specification_swap.total_delivery_install * 1
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>
              <template v-slot:item.unit_delivery_install="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{
                    Number(item.unit_delivery_install.toFixed(2)).toLocaleString(
                      "en-CA",
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      }
                    )
                  }}
                </div>
                <div v-else style="width: 200px">
                  {{
                    Number(
                      item.specification_swap.total_delivery_install / item.qty
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
              </template>

              <template v-slot:item.unit_cost="{ item }">
                {{
                  Number(item.unit_cost.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.unit_price="{ item }">
                {{
                  Number(item.unit_price.toFixed(2)).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template v-slot:item.total_price_sqft="{ item }">
                {{
                  Number((item.total_price / quote.sqft).toFixed(2)).toLocaleString(
                    "en-CA",
                    {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    }
                  )
                }}
              </template>
              <template v-slot:item.total_supply_price="{ item }">
                {{
                  Number((item.supply_price * item.qty).toFixed(2)).toLocaleString(
                    "en-CA",
                    {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    }
                  )
                }}
              </template>

              <template v-slot:item.supply_price_per_unit="{ item }">
                <div v-if="!item.specification_swap" style="width: 120px">
                  ${{ item.supply_price }}
                </div>
                <div v-else style="width: 120px">
                  {{
                    Number(item.specification_swap.unit_cogs).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </div>
                &lt;!&ndash; {{
                    Number(item.supply_price.toFixed(2)).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }} &ndash;&gt;
              </template>
              <template v-slot:item.name="{ item }">
                <div v-if="!item.specification_swap" style="width: 200px">
                  {{ item.name }}
                  <br />
                  <small v-if="Number(quote.base_spec_version)>=3.4" class="text-muted">{{ item.element.specification_key }}</small>
                </div>

                <div v-else class="d-flex">
                  <div class="d-flex" style="align-items: center">
                    <uil-arrow-growth style="color: #ff9f43" />
                  </div>
                  <div class="ml-1">
                    {{ item.name }}
                    <br />
                    <small class="text-muted">{{ item.specification_swap.name }}</small>
                  </div>
                </div>
              </template>

              <template v-slot:item.qty="{ item }">
                <div style="width: 100px">
                  {{ item.qty }}
                </div>
              </template>
              <template v-slot:item.internal_note="{ item }">
                <div v-if="item.specification_swap" style="width: 200px">
                  {{ item.specification_swap.internal_note }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="cursor: pointer; width: 100px" @click="showSwapSpec(item)">
                  <uil-refresh class="logo" size="15" />
                  Swap Spec
                </div>
              </template>
              &lt;!&ndash; end formatted data and custom formulas &ndash;&gt;

              <template slot="body.append">
                <tr
                  style="
                    background-color: #f8f8f8;
                    font-weight: 600;
                    color: #6e6b7b;
                    font-size: 12px;
                    text-transform: uppercase;
                  "
                  v-if="false"
                >
                  <th style="margin: 10px">Total</th>
                  <th v-if="totalColumnShow('vendor')"></th>
                  <th v-if="totalColumnShow('subcategory')"></th>
                  <th v-if="totalColumnShow('unit_cost')"></th>
                  <th v-if="totalColumnShow('mark_up_per_unit')"></th>
                  <th v-if="totalColumnShow('supply_price_per_unit')"></th>
                  <th v-if="totalColumnShow('unit_delivery_install')"></th>
                  <th v-if="totalColumnShow('unit_price')"></th>
                  <th v-if="totalColumnShow('qty')">
                    {{ Number(sumField("qty")) }}
                  </th>
                  <th v-if="totalColumnShow('total_cost')">
                    {{
                      Number(sumField("total_cost")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('cost_per_sqft')">
                    {{
                      Number(sumField("cost_per_sqft")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_markup')">
                    {{
                      Number(sumMarkUp.toFixed(2)).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_supply_price')">
                    {{
                      Number(sumTotalSupplyPrice).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_delivery_install')">
                    {{
                      Number(sumField("total_delivery_install")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_price')">
                    {{
                      Number(sumField("total_price")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </th>
                  <th v-if="totalColumnShow('total_price_sqft')">
                    {{
                      Number(sumField("total_price_sqft").toFixed(2)).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </th>
                </tr>
              </template>
              &lt;!&ndash; start no data slot &ndash;&gt;
              <template v-slot:no-data>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                  <p>
                    There are no additional base spec items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              &lt;!&ndash; end no data slot &ndash;&gt;

              &lt;!&ndash; start no data slot &ndash;&gt;
              <template v-slot:no-results>
                <div class="text-center my-2">
                  <h4 style="color: #b9b9c3"><b>No results to show</b></h4>
                  <p>
                    There are no additional base spec items to show for this
                    <br />
                    budget group.
                  </p>
                </div>
              </template>
              &lt;!&ndash; end no data slot &ndash;&gt;
            </v-data-table>
            <div v-else>
              <div class="text-center my-2">
                <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
                <p>
                  There are no additional base spec items to show for this
                  <br />
                  budget group.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>-->
        <div v-else>
          <h3 class="mt-2" style="color: #636363"><b>Base Spec</b></h3>
          <div class="text-center my-2">
            <h4 style="color: #b9b9c3"><b>No items to show</b></h4>
            <p>
              There are no additional base spec items to show for this <br />
              budget group.
            </p>
          </div>
        </div>
      </b-card>
      <!-- end base spec table  -->
    </div>
    <swap-spec
      :spec="itemToSwap"
      :swaped_spec="itemToSwap.specification_swap"
      :specType="'avit'"
      :base_spec_version="Number(quote.base_spec_version)"
      @resetSpec="resetSpec"
      @swapSpec="processSwapSpec"
    />
  </div>
</template>

<script>
import UninformatTableGrouping from "@/views/Quotes/Components/Utils/UniformatTableGrouping.vue";
import { UilInfoCircle, UilRefresh } from "@iconscout/vue-unicons";
import {
  BRow,
  BCol,
  BCard,
  BAlert,
  BFormInput,
  BFormCheckbox,
  BDropdownForm,
  BDropdown,
  BButton,
  BFormGroup,
  BDropdownDivider,
  BTooltip,
  BCardTitle,
} from "bootstrap-vue";
import SwapSpec from "./Modals/SwapSpec.vue";
import {
  UilExclamationTriangle,
  UilColumns,
  UilArrowGrowth,
} from "@iconscout/vue-unicons";
import Vuetify, {
  VApp,
  VDataTable,
  VIcon,
  VCol,
  VMenu,
  VBtn,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VDivider,
  VListItemGroup,
  VRow,
  VCheckbox,
} from "vuetify/lib";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    UilExclamationTriangle,
    BAlert,
    VDataTable,
    VMenu,
    VIcon,
    VBtn,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VDivider,
    VListItemGroup,
    VRow,
    VCheckbox,
    BFormInput,
    BFormCheckbox,
    BDropdownForm,
    BDropdown,
    UilColumns,
    BDropdown,
    BButton,
    BFormGroup,
    BDropdownDivider,
    BTooltip,
    UilInfoCircle,
    UilRefresh,
    SwapSpec,
    UilArrowGrowth,
    BCardTitle,
    UninformatTableGrouping
  },
  props: {
    address: {
      type: String,
    },
    quote: {
      type: Object,
    },
    signed: {
      type: Boolean,
    },
    change_orders: {
      type: Array,
    },
  },
  data() {
    return {
      // *selectAllVars
      allSelected: false,
      ASUallSelected: false,
      COallSelected: false,
      // AVIT dtable vars
      avitItems: [],
      search: "",
      filters: {
        // vendor: [],
        subcategory: [],
      },
      filteredItems: [],
      activeFilters: {},
      fields: [
        // {
        //   label: "Vendor",
        //   key: "vendor",
        // },
        {
          label: "Internal Note",
          key: "internal_note",
        },
        // {
        //   label: "Subgroup",
        //   key: "subcategory",
        // },
        // {
        //   label: "COGS / Unit",
        //   key: "unit_cost",
        // },
        // {
        //   label: "Markup / Unit",
        //   key: "mark_up_per_unit",
        // },
        {
          label: "Price / Unit",
          key: "supply_price_per_unit",
        },
        {
          label: "Delivery & Install / Unit",
          key: "unit_delivery_install",
        },
        // {
        //   label: "Price/Unit (W /D&I)",
        //   key: "unit_price",
        // },
        {
          label: "Quantity",
          key: "qty",
        },
        // {
        //   label: "Total COGS",
        //   key: "total_cost",
        // },
        // {
        //   label: "Total COGS/sqft",
        //   key: "cost_per_sqft",
        // },
        // {
        //   label: "Total Markup",
        //   key: "total_markup",
        // },
        // {
        //   label: "Total Price",
        //   key: "total_supply_price",
        // },
        // {
        //   label: "Total Delivery & Install",
        //   key: "total_delivery_install",
        // },
        {
          label: "Total Price (w/ D&I)",
          key: "total_price",
        },
        // {
        //   label: "Total Price/sqft (w/ D&I)",
        //   key: "total_price_sqft",
        // },
        {
          label: "Actions",
          key: "actions",
        },
      ],
      displayColumnsCheck: [
        "name",
        "internal_note",
        // "vendor",
        // "subcategory",
        // "unit_cost",
        "supply_price_per_unit",
        "unit_delivery_install",
        "qty",
        // "total_cost",
        "total_price",
        "actions",
      ],
      totalRows: 0,
      itemToSwap: {
        specification_swap: null,
      },
    };
  },

  mounted() {
    this.initFilters();
  },
  computed: {
    // * check box computed
    baseSpecSelectedAll() {
      if (this.displayColumnsCheck.length <= 10) {
        this.allSelected = false;
      } else {
        this.allSelected = true;
      }
    },
    // *end checkbox computed
    avitTableTotal() {
      let asuPrice = 0;
      let coPrice = 0;
      let caPrice = 0;
      let results = this.quote.avit_spec_quote.reduce(
        (total, obj) => parseFloat(obj.supply_price) * obj.qty + parseFloat(total),
        0
      );
      if (this.ASUItems.length > 0) {
        asuPrice = this.ASUItems.reduce((total, obj) => obj.total_cost + total, 0);
      }
      if (this.signed) {
        this.change_orders.map(
          (item) =>
            (coPrice =
              coPrice +
              item.change_order_lines
                .filter((x) => x.budget_group == "AV/IT")
                .reduce((total, obj) => obj.total_cost + total, 0))
        );
      }
      if (this.COItems.length > 0 && this.signed) {
        coPrice = this.COItems.reduce((total, obj) => obj.total_cost + total, 0);
      }
      if (!this.quote.avit_toggle) {
        results = 0;
      }
      caPrice = this.quote.allowances
        .filter((o) => o.budget_group == "AV/IT")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return results + asuPrice + coPrice + caPrice;
    },
    avitDelivertTotal() {
      let results = this.quote.avit_spec_quote.reduce(
        (total, obj) => obj.unit_delivery_install * obj.qty + total,
        0
      );
      if (!this.quote.avit_toggle) {
        results = 0;
      }
      return results;
    },
    avPriceTotal() {
      let asuPrice = 0;
      let coPrice = 0;
      let caPrice = 0;
      let results = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == "AV")
        .reduce(
          (total, obj) => parseFloat(obj.supply_price) * obj.qty + parseFloat(total),
          0
        );
      // if (this.ASUItems.length > 0) {
      //   asuPrice = this.ASUItems.reduce((total, obj) => obj.total_price + total, 0);
      // }
      if (this.COItems.length > 0 && this.signed) {
        coPrice = this.COItems.reduce((total, obj) => obj.total_price + total, 0);
      }
      if (!this.quote.avit_toggle) {
        results = 0;
      }
      // caPrice = this.quote.allowances
      //   .filter((o) => o.budget_group == "AV/IT")
      //   .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return results + asuPrice + coPrice + caPrice;
    },
    itCostTotal() {
      let asuPrice = 0;
      let coPrice = 0;
      let caPrice = 0;
      let results = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == "IT")
        .reduce(
          (total, obj) =>
            parseFloat(obj.total_cost) +
            parseFloat(obj.total_delivery_install) +
            parseFloat(total),
          0
        );

      // if (this.ASUItems.length > 0) {
      //   asuPrice = this.ASUItems.reduce((total, obj) => obj.total_cost + total, 0);
      // }
      if (this.COItems.length > 0 && this.signed) {
        coPrice = this.COItems.reduce((total, obj) => obj.total_cost + total, 0);
      }
      if (!this.quote.avit_toggle) {
        results = 0;
      }
      // caPrice = this.quote.allowances
      //   .filter((o) => o.budget_group == "AV/IT")
      //   .reduce((total, obj) => Number(obj.total_cost) + total, 0);
      return results + asuPrice + coPrice + caPrice;
    },
    sumMarkUp() {
      return this.avitItems.reduce(
        (a, b) => a + (this.markup(b.supply_price, b.unit_cost) * b.qty || 0),
        0
      );
    },
    sumTotalSupplyPrice() {
      return this.filteredItems.reduce((a, b) => a + (b.supply_price * b.qty || 0), 0);
    },
    headers() {
      return [
        {
          text: "Element",
          value: "name",
        },
        {
          text: "Internal Note",
          value: "internal_note",
        },
        // {
        //   text: "Vendor",
        //   value: "vendor",
        //   filter: (value) => {
        //     return this.activeFilters.vendor
        //       ? this.activeFilters.vendor.includes(value)
        //       : false;
        //   },
        // },
        {
          text: "Subgroup",
          value: "subcategory",
          sortable: false,
          filter: (value) => {
            return this.activeFilters.subcategory
              ? this.activeFilters.subcategory.includes(value)
              : false;
          },
        },
        {
          text: "COGS / Unit",
          value: "unit_cost",
        },
        {
          text: "Markup / Unit",
          value: "mark_up_per_unit",
        },
        {
          text: "Price / Unit",
          value: "supply_price_per_unit",
          sortable: false,
        },
        {
          text: "Delivery & Install / Unit",
          value: "unit_delivery_install",
        },
        {
          text: "Price/Unit (W /D&I)",
          value: "unit_price",
        },
        {
          text: "Quantity",
          value: "qty",
        },
        {
          text: "Total COGS",
          value: "total_cost",
        },
        {
          text: "Total COGS/SQFT",
          value: "cost_per_sqft",
        },
        {
          text: "Total Markup",
          value: "total_markup",
        },
        {
          text: "Total Price",
          value: "total_supply_price",
        },
        {
          text: "Total Delivery & Install",
          value: "total_delivery_install",
        },
        // {
        //   text: "Total Markup",
        //   value: "total_markup",
        // },
        {
          text: "Total Price (w/ D&I)",
          value: "total_price",
        },
        {
          text: "Total Price/sqft (w/ D&I)",
          value: "total_price_sqft",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: "false",
        },
      ].filter((s) => this.displayColumnsCheck.includes(s.value));
    },
    // avit global totals
    avitGlobalSubTotal() {
      let sum = 0;
      if (this.quote.base_spec_version < 3.3) {
        sum += this.quote.avit_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitTotals("AV") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.avit_toggle ? this.avitDI("AV") : 0;
      } else {
        sum += this.quote.it_toggle ? this.avitTotals("IT") : 0;
        sum += this.quote.av_toggle ? this.avitTotals("AV") : 0;
        sum += this.quote.it_toggle ? this.avitDI("IT") : 0;
        sum += this.quote.av_toggle ? this.avitDI("AV") : 0;
      }
      return sum;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    desserts(val) {
      this.initFilters();
    },
  },
  methods: {
    clicked(item) {
      // console.log(item);
      // const { sf_opportunity_id } = item;
      // this.$router.push({
      //   path: `/quote.avit_spec_quote/${sf_opportunity_id}`,
      // });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    initFilters() {
      this.avitItems = this.quote.avit_spec_quote;
      // create new property for custom filter usage
      // if (this.avitItems.length > 0) {
      //   this.avitItems.map((f) => {
      //     f.vendor = f.element.vendor;
      //   });
      // }
      this.totalRows = this.avitItems;
      let col = "";
      for (col in this.filters) {
        this.filters[col] = this.quote.avit_spec_quote
          .map((value) => value[col])
          .filter((value, index, _arr) => _arr.indexOf(value) == index)
          .sort();
      }
      this.activeFilters = Object.assign({}, this.filters);
    },

    toggleAll(col) {
      this.activeFilters[col] = this.quote.avit_spec_quote
        .map((value) => value[col])
        .filter((value, index, _arr) => _arr.indexOf(value) == index)
        .sort();
    },
    isFilterActive(name, value) {
      return this.activeFilters[name] && this.activeFilters[name].includes(value)
        ? true
        : false;
      //return this.activeFilters[name] ? this.activeFilters[name].includes(value) : true;
    },

    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sumField(key) {
      // sum data in give key (property)
      //let itemsFiltered = this.$refs.myTable.$children[0].computedItems
      if (key == "total_price_sqft") {
        return this.filteredItems.reduce(
          (a, b) => a + (b.total_price / this.quote.sqft || 0),
          0
        );
      } else {
        return this.filteredItems.reduce((a, b) => a + (b[key] || 0), 0);
      }
      // return 0;
    },

    currentItems: function (value) {
      this.filteredItems = value;
    },
    // ff&e functions
    markup(supply, cost) {
      return supply - cost;
    },
    // display total columns
    totalColumnShow(col) {
      let isSelected = false;
      if (this.displayColumnsCheck.length > 0) {
        let res = this.displayColumnsCheck.filter((c) => c == col);
        if (res.length != 0) {
          isSelected = true;
        }
      }
      return isSelected;
    },

    // * select all methods
    AllDrop(checked) {
      if (checked) {
        this.displayColumnsCheck = ["name"];
        if (this.fields.length > 0) {
          this.fields.map((f) => {
            this.displayColumnsCheck.push(f.key);
          });
        }
      } else {
        this.displayColumnsCheck = ["name"];
      }
    },

    // * reset cols
    resetCols(table) {
      if (table == "base") {
        this.displayColumnsCheck = [
          "name",
          "unit_cost",
          // "vendor",
          "subcategory",
          "qty",
          "total_cost",
          "cost_per_sqft",
          "total_delivery_install",
          "actions",
        ];
      } else if (table == "asu") {
        this.ASUdisplayColumnsCheck = [
          "name",
          "internal_note",
          "unit_cost",
          "total_cost",
          "cost_per_sqft",
        ];
      } else if (table == "cash") {
        this.CAdisplayColumnsCheck = [
          "name",
          "internal_note",
          "unit_cost",
          "total_cost",
          "cost_per_sqft",
        ];
      } else {
        this.COdisplayColumnsCheck = ["name", "internal_note", "quantity", "total_cost"];
      }
    },
    clicked(item) {
      // console.log(item);
      // const { sf_opportunity_id } = item;
      // this.$router.push({
      //   path: `/quote.ffe_spec_quote/${sf_opportunity_id}`,
      // });
    },
    //* swap spec functions
    showSwapSpec(item) {
      this.itemToSwap = item;
      this.$bvModal.show("swap_spec_modal_avit");
    },
    saveSwapSpec(item) {
      this.$bvModal.hide("swap_spec_modal_avit");
    },
    processSwapSpec(swappedSpec) {
      let itemSwapped = [];
      if (swappedSpec.specType == "avit") {
        this.avitItems.map((avit) => {
          if (avit.id == swappedSpec.avit_spec_id) {
            avit.specification_swap = swappedSpec;
            itemSwapped = avit.specification_swap;
          }
        });
        this.$emit("swappSpec", itemSwapped);
      }
    },
    resetSpec(swappedSpec) {
      if (swappedSpec.specType == "avit") {
        this.avitItems.map((avit) => {
          if (avit.id == swappedSpec.avit_spec_id) {
            avit.specification_swap = null;
          }
        });
        this.$emit("resetSpec", swappedSpec);
      }
    },
    avitTotals(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.supply_price * obj.qty)
              : obj.specification_swap.total_price * 1) + total,
          0
        );
      return total;
    },
    avitDI(option) {
      let total = 0;
      total = this.quote.avit_spec_quote
        .filter((x) => x.subcategory == option)
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.unit_delivery_install * obj.qty)
              : Number(obj.specification_swap.total_delivery_install * 1)) + total,
          0
        );
      return total;
    },
  },
};
</script>

<style lang="scss" scoped>
.title_divider {
  border: none;
  border-left: 1px solid rgba(31, 41, 47, 0.05);
  height: auto;
  width: 1px;
  margin-right: 25px;
}
.card_title {
  color: #6e6b7b;
  margin-bottom: 4px;
  font-size: 14px;
}
.theme--light.v-data-table .v-data-table-header th {
  color: red;
}
</style>
<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: rgba(92, 177, 231, 0.12) !important;
  text-transform: uppercase;
}
.v-data-table::v-deep th {
  font-size: 14px !important;
}
.v-data-table::v-deep td {
  font-size: 14px !important;
  color: #6e6b7b;
}
</style>
<style lang="scss">
@import "./reset.css";
// @import './../../../../src/@core/scss/base/bootstrap.scss';
@import "./../../../../src/@core/scss/core.scss";
@import "../../../../src/assets/scss/style.scss";
</style>
